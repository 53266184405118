import * as React from 'react';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

export interface Props {
    show: boolean;
    image: string;
    sx?: SxProps<Theme>;
}

export default function SplashImage(p: Props) {
    const theme = useTheme();
    return (
        <Slide direction="left" in={p.show}>
            <Box
                classes="hero-image"
                sx={{
                    ...p.sx,
                }}
            >
                <img
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    src={p.image}
                />
            </Box>
        </Slide>
    );
}

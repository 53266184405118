import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import Image from 'mui-image';
import { SocialIcon } from 'react-social-icons';
import smallLogo from './assets/logo-new.png';
import EmailCapture from './EmailCapture';

export interface Props {
    isMobile: boolean;
    isTablet: boolean;
    sx?: SxProps<Theme>;
}

export default function Footer(p: Props) {
    return (
        <Box
            className="page-column"
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr minmax(200px, 600px) 1fr',
                gridTemplateRows: 'minmax(140px, auto) 1fr 1fr',
                gridTemplateAreas: `". header ."
                ". signup ."
                "social social social"`,
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <Box className="header" sx={{ gridArea: 'header' }}>
                <Typography
                    variant="h1"
                    align="center"
                    sx={{ marginBottom: '16px' }}
                >
                    Stay Informed
                </Typography>
                <Typography variant="h6" align="center">
                    Sign up to be notified as soon as we launch
                </Typography>
            </Box>
            <Box
                sx={{
                    gridArea: 'signup',
                    justifySelf: 'stretch',
                }}
            >
                <EmailCapture isMobile={p.isMobile} isTablet={p.isTablet} />
            </Box>
            <Box
                className="contact"
                sx={{
                    gridArea: 'social',
                    flexShrink: '0',
                    width: '100%',
                    display: 'grid',
                    placeItems: 'center',
                    paddingBottom: '50px',
                }}
            >
                <Image
                    src={smallLogo}
                    width="150px"
                    style={{ paddingBottom: '50px' }}
                />
                <Typography variant="body1" align="center">
                    info@kalaam.co
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '5px',
                    }}
                >
                    <SocialIcon
                        bgColor="#ffc600"
                        fgColor="#000000"
                        url="https://www.facebook.com/"
                        target="_blank"
                        style={{ height: 40, width: 40 }}
                    />
                    <SocialIcon
                        bgColor="#ffc600"
                        fgColor="#000000"
                        url="https://twitter.com/Kalaamaudio"
                        target="_blank"
                        style={{ height: 40, width: 40 }}
                    />
                    <SocialIcon
                        bgColor="#ffc600"
                        fgColor="#000000"
                        url="https://www.instagram.com/kalaamhalalaudio/"
                        target="_blank"
                        style={{ height: 40, width: 40 }}
                    />
                    <SocialIcon
                        bgColor="#ffc600"
                        fgColor="#000000"
                        url="https://www.linkedin.com/company/kalaamhalalaudio"
                        target="_blank"
                        style={{ height: 40, width: 40 }}
                    />
                    {/* <SocialIcon */}
                    {/*     bgColor="#ffc600" */}
                    {/*     fgColor="#000000" */}
                    {/*     url="https://www.tiktok.com/@kalaamhalalaudio" */}
                    {/*     target="_blank" */}
                    {/*     style={{ height: 40, width: 40 }} */}
                    {/* /> */}
                </Box>
            </Box>
        </Box>
    );
}

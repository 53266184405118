import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { SxProps, Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import FeatureCard from './FeatureCard';
import halalChoice from './icons/halal-choice.svg';
import trackProgress from './icons/track-progress.svg';
import allInOne from './icons/all-in-one.svg';

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

export interface Props {
    show: boolean;
    isMobile: boolean;
    isTablet: boolean;
    sx?: SxProps<Theme>;
}

export default function Features(p: Props) {
    return (
        <Slide direction="up" in={p.show} style={{ overflow: 'none' }}>
            <Box
                className="page-column"
                sx={{
                    width: '100%',
                    paddingX: '20px',
                    display: 'grid',
                    gap: '50px',
                    gridTemplateColumns: '1fr minmax(200px, 600px) 1fr',
                    gridTemplateRows: 'minmax(100px, auto) 1fr',
                    gridTemplateAreas: `". header ."
                "main main main"`,
                    justifyItems: 'center',
                    alignItems: 'center',
                }}
            >
                <SecondaryTypography
                    variant="h1"
                    textAlign="center"
                    sx={{ gridArea: 'header' }}
                >
                    Making Halal Accessible
                </SecondaryTypography>
                <Box
                    className="feature-cards"
                    sx={{
                        gridArea: 'main',
                        flexShrink: '0',
                        width: '100%',
                        display: 'flex',
                        gap: '20px',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingBottom: '50px',
                    }}
                >
                    <FeatureCard
                        imageSrc={halalChoice}
                        title="The Halal Choice"
                        body="Our mission at Kalaam is to encourage the Ummah to listen to halal audio. Create your own playlists!"
                    />
                    <FeatureCard
                        imageSrc={trackProgress}
                        title="Track Your Progress"
                        body="We are proactive in helping you achieve your goals. Set Quran goals and track your progress!"
                    />
                    <FeatureCard
                        imageSrc={allInOne}
                        title="All-in-One"
                        body="Kalaam is the only app with Quran, Nasheeds, Podcasts, and Audiobooks. Everything you need at your fingertips."
                    />
                </Box>
            </Box>
        </Slide>
    );
}

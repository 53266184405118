import * as React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Image from 'mui-image';
import screenshot1 from './assets/screenshot-1.jpg';
import screenshot2 from './assets/screenshot-2.jpg';
import screenshot3 from './assets/screenshot-3.jpg';
import screenshot4 from './assets/screenshot-4.jpg';
import screenshot5 from './assets/screenshot-5.jpg';
import screenshot6 from './assets/screenshot-6.jpg';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper-styles.css';

// import required modules
import { Autoplay, Navigation } from 'swiper';

const StyledImage = (p: { src: string }) => (
    <Image
        src={p.src}
        wrapperStyle={{
            minHeight: '500px',
            minWidth: 0,
            maxWidth: '283px',
        }}
        style={{
            objectFit: 'contain',
        }}
    />
);

export interface Props {
    isMobile: boolean;
    isTablet: boolean;
    // Grid area to apply to this component
    gridArea: string;
}

export default function Carousel(p: Props) {
    return (
        <Swiper
            slidesPerView={p.isMobile ? 1 : 3}
            autoHeight={true}
            spaceBetween={30}
            centeredSlides={true}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: true,
            }}
            modules={[Autoplay, Navigation]}
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexShrink: 0,
                gridArea: p.gridArea,
                alignSelf: 'start',
            }}
        >
            <SwiperSlide>
                <StyledImage src={screenshot1} />
            </SwiperSlide>
            <SwiperSlide>
                <StyledImage src={screenshot2} />
            </SwiperSlide>
            <SwiperSlide>
                <StyledImage src={screenshot3} />
            </SwiperSlide>
            <SwiperSlide>
                <StyledImage src={screenshot4} />
            </SwiperSlide>
            <SwiperSlide>
                <StyledImage src={screenshot5} />
            </SwiperSlide>
            <SwiperSlide>
                <StyledImage src={screenshot6} />
            </SwiperSlide>
        </Swiper>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';

// How this was made:
// Made Google Docs version of our privacy policy: https://docs.google.com/document/d/1Ed1sBhgTbUyQ8z6RN87qtRrSMOCzeOTQwSPUbwFOEZg/edit
// Used extension "Docs to Markdown", output to HTML instead
// Pasted the entirety (except title - manually made that a h1) into the Box below
// had to add closing tags for <li> for some reason
// That's it

export default function Privacy() {
    return (
        <Box
            sx={{
                marginX: '30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '600px',
            }}
        >
            <h1>Kalaam Ltd Privacy Policy</h1>
            <p>
                Kalaam Ltd, having address at 71-75 Shelton Street Covent Garden
                London United Kingdom WC2H 9JQ with company number 13993276
                (thereafter “<strong>Kalaam</strong>”) has drafted this privacy
                policy to explain how our organisation uses the personal data we
                collect from you when you use our app (Kalaam – Halal Audio) and
                website (<a href="https://kalaam.co">https://kalaam.co</a>).
            </p>
            <ul>
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How do we store your data?</li>
                <li>Marketing</li>
                <li>What are your data protection rights?</li>
                <li>What are cookies?</li>
                <li>How do we use cookies?</li>
                <li>What types of cookies do we use?</li>
                <li>How to manage your cookies</li>
                <li>Privacy policies of other websites</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
            </ul>
            <h2>
                <strong>What data do we collect?</strong>
            </h2>

            <p>Kalaam may collect the following data:</p>
            <ul>
                <li>
                    Personal identification information (Name, email address,
                    gender, date of birth, phone number, street address,
                    country, profile photo, etc.)
                </li>
                <li>
                    Preferences, e.g. your service option or account settings.
                </li>
                <li>
                    Favourite artists, favourite audio content, playlists you
                    create.
                </li>
                <li>
                    Other data about how you use our services, such as search
                    queries, streaming history, browsing history, etc.
                </li>
                <li>
                    Content you provide when taking part in Kalaam promotions,
                    such as contests.
                </li>
                <li>
                    Content you post to any part of the Kalaam service. For
                    example: images, audio, text, etc.
                </li>
                <li>
                    Technical data, e.g. URL information, online identifiers,
                    information about the devices you use (e.g. device IDs,
                    network connection type, language, operating system, Kalaam
                    application version, etc.)
                </li>
                <li>
                    Your general (non-precise) location, which we may learn from
                    technical data (e.g. your IP address) or payment currency.
                    We need this to meet geographic requirements in agreement
                    with owners of content, and to deliver relevant content and
                    advertising to you.
                </li>
                <li>
                    Your device sensor data, if needed to provide features of
                    the Kalaam service that require this data.
                </li>
                <li>
                    Payment and purchase data (may include name, date of birth,
                    payment method type (credit or debit card), payment method
                    details, ZIP/postal code, mobile phone number, details of
                    your purchase and payment history).
                </li>
                <li>Survey and research data</li>
            </ul>
            <h2>
                <strong>How do we collect your data?</strong>
            </h2>

            <p>
                You directly provide Kalaam with most of the data we collect. We
                collect data and process data when you:
            </p>
            <ul>
                <li>Sign up to our website or services.</li>
                <li>Use or view our website via your browser's cookies.</li>
                <li>Use our services via your device.</li>
                <li>
                    Respond to our take part in our surveys or competitions.
                </li>
            </ul>
            <p>
                Kalaam may also receive your data indirectly from the following
                sources:
            </p>
            <ul>
                <li>
                    Authentication partners, in order to provide social login
                    and other helpful login options.
                </li>
                <li>
                    Technical service partners, e.g. to map IP addresses to
                    non-precise location data to allow us to provide Kalaam
                    services, content, and features.
                </li>
                <li>Payment partners</li>
                <li>Advertising and marketing partners</li>
            </ul>
            <h2>
                <strong>How will we use your data?</strong>
            </h2>

            <p>Kalaam collects your data so that we can:</p>
            <ul>
                <li>Provide the Kalaam service.</li>
                <li>Manage your account.</li>
                <li>
                    Email you with special offers on other products and services
                    we think you might like.
                </li>
                <li>
                    Understand how our products and services are being used
                    (e.g. in order to improve our products and services).
                </li>
                <li>Diagnose and fix issues with the Kalaam service.</li>
                <li>Develop new features to the Kalaam service.</li>
                <li>
                    Engage in marketing, promotion and advertising purposes.
                </li>
                <li>
                    Comply with legal obligations or requests from law
                    enforcement.
                </li>
                <li>Conduct research and surveys.</li>
                <li>
                    Personalise your experience using our products and services.
                </li>
            </ul>
            <p>
                If you agree, Kalaam may share your data with our partner
                companies so that they may offer you their products and
                services.
            </p>
            <p>
                When Kalaam processes your order for our services, it may send
                your data to, and also use the resulting information from,
                credit reference agencies to prevent fraudulent purchases.
            </p>
            <h2>
                <strong>How do we store your data?</strong>
            </h2>

            <p>
                Kalaam securely stores your data at Amazon AWS US East 1
                (Northern Virginia) Region. Data is encrypted at rest and in
                transit. At rest the industry standard AES-256 encryption
                algorithm is used. Access control to all data is strictly
                managed to prevent unauthorised access.
            </p>
            <p>
                Kalaam will keep your data only as long as necessary to provide
                you with the Kalaam services and for legitimate and essential
                business purposes. For example, in order to deliver a
                personalised service to users we will typically keep your
                streaming history for the life of an account. However, any data
                you can see and edit in the app (e.g. profile picture) is
                entirely under your control and you can modify or delete it as
                you see fit - we do not keep such data after you modify it.
            </p>
            <h1>
                <strong>Marketing</strong>
            </h1>

            <p>
                Kalaam would like to send you information about products and
                services of ours that we think you might like.
            </p>
            <p>
                If you have agreed to receive marketing, you may always opt out
                at a later date.
            </p>
            <p>
                You have the right at any time to stop Kalaam from contacting
                you for marketing purposes.
            </p>
            <h2>
                <strong>What are your data protection rights?</strong>
            </h2>

            <p>
                Kalaam would like to make sure you are fully aware of all of
                your data protection rights. Every user is entitled to the
                following:
            </p>
            <p>
                <strong>The right to access – </strong>You have the right to
                request Kalaam for copies of your personal data. We may charge
                you a small fee for this service.
            </p>
            <p>
                <strong>The right to rectification – </strong>You have the right
                to request that Kalaam correct any information you believe is
                inaccurate. You also have the right to request Kalaam to
                complete information you believe is incomplete.
            </p>
            <p>
                <strong>The right to erasure </strong>– You have the right to
                request that Kalaam erase your personal data, under certain
                conditions.
            </p>
            <p>
                <strong>The right to restrict processing </strong>– You have the
                right to request that Kalaam restrict the processing of your
                personal data, under certain conditions.
            </p>
            <p>
                <strong>The right to object to processing</strong> – You have
                the right to object to Kalaam’s processing of your personal
                data, under certain conditions.
            </p>
            <p>
                <strong>The right to data portability </strong>– You have the
                right to request that Kalaam transfer the data that we have
                collected to another organisation, or directly to you, under
                certain conditions.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us at our email listed below.
            </p>
            <h2>
                <strong>What are cookies?</strong>
            </h2>

            <p>
                Cookies are text files placed on your computer to collect
                standard Internet log information and visitor behaviour
                information. When you visit our websites, we may collect
                information from you automatically through cookies or similar
                technology.
            </p>
            <h2>
                <strong>How do we use cookies?</strong>
            </h2>

            <p>
                Kalaam uses cookies in a range of ways to improve your
                experience on our website, including:
            </p>
            <ul>
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
            </ul>
            <h2>
                <strong>What types of cookies do we use?</strong>
            </h2>

            <p>
                There are a number of different types of cookies, however, our
                website uses:
            </p>
            <ul>
                <li>
                    Functionality – Kalaam uses these cookies so that we
                    recognise you on our website and remember your previously
                    selected preferences. These could include what language you
                    prefer and location you are in. A mix of first-party and
                    third-party cookies are used.
                </li>
                <li>
                    Advertising – Kalaam uses these cookies to collect
                    information about your visit to our website, the content you
                    viewed, the links you followed and information about your
                    browser, device, and your IP address. Kalaam sometimes
                    shares some limited aspects of this data with third parties
                    for advertising purposes. We may also share online data
                    collected through cookies with our advertising partners.
                    This means that when you visit another website, you may be
                    shown advertising based on your browsing patterns on our
                    website.
                </li>
            </ul>
            <h1>
                <strong>How to manage cookies</strong>
            </h1>

            <p>
                You can set your browser not to accept cookies. However, in a
                few cases, some of our website features may not function as a
                result.
            </p>
            <h2>
                <strong>Privacy policies of other websites</strong>
            </h2>

            <p>
                The Kalaam website contains links to other websites. Our privacy
                policy applies only to our website, so if you click on a link to
                another website, you should read their privacy policy.
            </p>
            <h2>
                <strong>Changes to our privacy policy</strong>
            </h2>

            <p>
                Kalaam keeps its privacy policy under regular review and places
                any updates on this web page. This privacy policy was last
                updated on 28 September 2022.
            </p>
            <h2>
                <strong>How to contact us</strong>
            </h2>

            <p>
                If you have any questions about Kalaam’s privacy policy, the
                data we hold on you, or you would like to exercise one of your
                data protection rights, please do not hesitate to contact us.
            </p>
            <p>Email us at: support@kalaam.co</p>
            <h2>
                <strong>How to contact the appropriate authority</strong>
            </h2>

            <p>
                Should you wish to report a complaint or if you feel that Kalaam
                has not addressed your concern in a satisfactory manner, you may
                contact the Information Commissioner's Office.
            </p>
            <p>
                Email Address: <strong>dpo@ico.org.uk</strong>
            </p>
        </Box>
    );
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

import EmailCapture from './EmailCapture';

export interface Props {
    show: boolean;
    isMobile: boolean;
    isTablet: boolean;
    sx?: SxProps<Theme>;
}

export default function SplashText(p: Props) {
    return (
        <Slide direction="up" in={p.show}>
            <Box sx={{ ...p.sx }}>
                <Typography variant="h5" paddingBottom="20px">
                    Quran. Nasheeds. Podcasts. Audiobooks.
                </Typography>
                <Typography variant="h1">
                    Kalaam – Your Halal Audio App
                </Typography>
                <Typography
                    variant="body1"
                    margin="35px 0px 38px 0px"
                    maxWidth="430px"
                >
                    Our all-in-one free halal audio app will be launching soon.
                    Sign up to be notified as soon as we launch!
                </Typography>
                <EmailCapture isMobile={p.isMobile} isTablet={p.isTablet} />
            </Box>
        </Slide>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/system';

const FormHelperBlackText = styled(FormHelperText)(({ theme }) => ({
    color: 'black',
    ...(theme.typography as any).body1,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '5px',
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
    },
}));

// Created form in Mailchimp and extracted the action url
const url =
    'https://kalaam.us10.list-manage.com/subscribe/post?u=4fec5e9071d64caac2e0cc780&amp;id=69a1fa0729';

export interface Props {
    isMobile: boolean;
    isTablet: boolean;
}

type Inputs = {
    name: string;
    email: string;
};

export default function EmailCapture(p: Props) {
    const { handleSubmit, control, formState } = useForm<Inputs>();

    const onSubmit =
        (subscribe: (data: EmailFormFields) => void) => (data: Inputs) => {
            subscribe({
                EMAIL: data.email,
                NAME: data.name,
            } as any);
        };

    return (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <form onSubmit={handleSubmit(onSubmit(subscribe))}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <StyledTextField
                                    onChange={onChange}
                                    value={value}
                                    placeholder="Enter your name"
                                    variant="outlined"
                                    error={!!formState.errors.name}
                                />
                            )}
                            rules={{ required: 'Name required' }}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <StyledTextField
                                    onChange={onChange}
                                    value={value}
                                    placeholder="Enter your email"
                                    variant="outlined"
                                    error={!!formState.errors.email}
                                />
                            )}
                            rules={{
                                required: 'Email required',
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: 'Please enter a valid email',
                                },
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent:
                                    p.isMobile || p.isTablet
                                        ? 'center'
                                        : 'flex-start',
                            }}
                        >
                            <LoadingButton
                                type="submit"
                                loading={status === 'sending'}
                                loadingIndicator="Subscribing…"
                                variant="contained"
                                disabled={status === 'success'}
                                disableRipple={status === 'success'}
                                disableTouchRipple={status === 'success'}
                                disableElevation={status === 'success'}
                                sx={{
                                    marginY: '20px',
                                    textTransform: 'none',
                                    width: '180px',
                                    height: '50px',
                                }}
                            >
                                {status === 'success'
                                    ? 'Subscribed!'
                                    : 'Subscribe Now'}
                            </LoadingButton>
                        </Box>
                        <FormHelperBlackText>
                            {status === 'success'
                                ? 'Bismillah. Thank you for signing up! We will update you as we progress!'
                                : formState.errors.name &&
                                  formState.errors.email
                                ? `${formState.errors.name.message}, ${formState.errors.email.message}`
                                : formState.errors.name?.message ??
                                  formState.errors.email?.message ??
                                  ' '}
                        </FormHelperBlackText>
                        <FormHelperBlackText>
                            {status === 'error'
                                ? 'Something went wrong. Please review your details and try again.'
                                : ' '}
                        </FormHelperBlackText>
                    </Box>
                </form>
            )}
        />
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Carousel from './Carousel';

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

export interface Props {
    isMobile: boolean;
    isTablet: boolean;
    sx?: SxProps<Theme>;
}

export default function SneakPeek(p: Props) {
    return (
        <Box
            className="page-column"
            sx={{
                width: '100%',
                paddingY: '30px',
                paddingX: '20px',
                display: 'grid',
                gap: '130px 50px',
                gridTemplateColumns: '1fr minmax(200px, 600px) 1fr',
                gridTemplateRows: 'minmax(140px, auto) 1fr',
                gridTemplateAreas: `". header ."
                "main main main"`,
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{ gridArea: 'header' }}>
                <SecondaryTypography
                    variant="h1"
                    align="center"
                    sx={{ marginBottom: '16px' }}
                >
                    A Sneak Peek!
                </SecondaryTypography>
                <Typography variant="h6" color="white" align="center">
                    Take an exclusive look at our design and special features.
                    All your halal audio needs packed into one app.
                </Typography>
            </Box>
            <Carousel
                isMobile={p.isMobile}
                isTablet={p.isTablet}
                gridArea="main"
            />
        </Box>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import background from './assets/black-golen.png';
import SplashDesktop from './SplashDesktop';
import SplashMobile from './SplashMobile';
import SplashTablet from './SplashTablet';
import SneakPeek from './SneakPeek';
import Features from './Features';
import Footer from './Footer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-intersection-observer';

export default function App() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

    return (
        <>
            <Box
                className="section"
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    backgroundImage: isTablet ? `unset` : `url(${background})`,
                    backgroundPosition: `100% 100%`,
                    backgroundSize: `100vw 100vh`,
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    paddingY: '30px',
                }}
            >
                {isMobile ? (
                    <SplashMobile />
                ) : isTablet ? (
                    <SplashTablet />
                ) : (
                    <SplashDesktop />
                )}
            </Box>
            <Box
                className="section"
                sx={{
                    backgroundColor: `#000000`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingTop: !isMobile && !isTablet ? '100px' : '30px',
                    paddingBottom: '30px',
                }}
            >
                <SneakPeek isMobile={isMobile} isTablet={isTablet} />
            </Box>
            <Box
                className="section"
                ref={ref}
                sx={{
                    backgroundColor: `#000000`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingY: '30px',
                }}
            >
                <Features
                    show={inView}
                    isMobile={isMobile}
                    isTablet={isTablet}
                />
            </Box>
            <Box
                className="section"
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: '30px 20px',
                }}
            >
                <Footer isMobile={isMobile} isTablet={isTablet} />
            </Box>
        </>
    );
}

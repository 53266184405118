import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
let theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffc600',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        h1: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '48px',
            fontWeight: '600',
        },
        h5: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '20px',
            fontWeight: '600',
        },
        h6: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '21px',
            fontWeight: '400',
        },
        body1: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '16px',
            fontWeight: '400',
        },
        body2: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '18px',
            fontWeight: '400',
        },
        button: {
            fontFamily: '"HK Grotesk", sans-serif',
            fontSize: '18px',
            fontWeight: '700',
        },
    },
    transitions: {
        duration: {
            enteringScreen: 1000,
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;

import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import SplashText from './SplashText';
import SplashImage from './SplashImage';

import Image from 'mui-image';

import smallLogo from './assets/logo-new.png';
import hero from './assets/hero-1-optimised.png';

export interface Props {
    sx?: SxProps<Theme>;
}

export default function SplashTablet(p: Props) {
    const { ref, inView } = useInView({ triggerOnce: true });

    return (
        <Box
            className="page-column"
            sx={{
                maxWidth: '1320px',
                width: '100%',
                paddingX: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}
        >
            <Box className="header">
                <Image src={smallLogo} width="300px" />
            </Box>
            <Box
                className="splash-column"
                ref={ref}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingX: '20px',
                }}
            >
                <SplashImage
                    show={inView}
                    sx={{
                        height: '638px',
                        padding: '20px',
                    }}
                    image={hero}
                />
                <SplashText
                    show={inView}
                    isMobile={false}
                    isTablet={true}
                    sx={{ maxWidth: '567px' }}
                />
            </Box>
        </Box>
    );
}

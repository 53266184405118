import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface Props {
    imageSrc: string;
    title: string;
    body: string;
}

export default function FeatureCard(p: Props) {
    return (
        <Box
            className="feature-card"
            sx={{
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingY: '30px',
                paddingX: '40px',
            }}
        >
            <img
                src={p.imageSrc}
                width="73px"
                height="73px"
                style={{ marginBottom: '30px' }}
            />
            <Typography variant="h5" marginBottom="20px">
                {p.title}
            </Typography>
            <Typography variant="body1" color="#000B33B3" maxWidth="333px">
                {p.body}
            </Typography>
        </Box>
    );
}
